import React from 'react';

import { TrashCan, WatsonHealthStackedMove } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import { MenuItem } from 'components/menu/MenuItem';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import DataTrayAddButton from 'app/components/DataTray/TerritoryGrid/DataTrayAddButton/DataTrayAddButton';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';
import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

import useCanUser from 'app/hooks/useCanUser';
import useTreatment from 'app/hooks/useTreatment';

import { BattlecardType, PlanningCycleModal, FileType, HierarchyQuerySpec, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import MapGridToggle from './MapGridToggle';
import style from './TerritoryGridActionButtons.module.pcss';

const b = block(style);

interface MoveTerritoryButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const MoveTerritoryButton = ({ onClick, disabled }: MoveTerritoryButtonProps) => {
  return (
    <IconButton
      title={formatMessage('MOVE')}
      type="button"
      icon={<WatsonHealthStackedMove />}
      testId={'move-territory-button'}
      intent="primary"
      onClick={onClick}
      disabled={disabled}
    />
  );
};

const BulkDeleteTerritoriesButton = ({ onClick, disabled, showTooltip }) => {
  return (
    <IconButton
      title={formatMessage('DELETE')}
      type="button"
      icon={<TrashCan />}
      testId={'delete-territories-button'}
      intent="danger"
      onClick={onClick}
      disabled={disabled}
      tooltipText={showTooltip && formatMessage('BULK_DELETE_DISABLED_TOOLTIP')}
    />
  );
};

interface TerritoryGridActionButtonsProps {
  selectedHierarchy: string;
  setSelectedHierarchy: (unknown) => void;
  hierarchies: HierarchyQuerySpec;
  moveTerritoriesTGTypes: unknown[];
  selectedNodes: unknown[];
  setOpenedModal: (dataTrayModal: PlanningCycleModal) => void;
  onOpenMap: () => void;
  onCloseMap: () => void;
  isFilterPresent: boolean;
}

const TerritoryGridActionButtons: React.FC<TerritoryGridActionButtonsProps> = ({
  selectedHierarchy,
  setSelectedHierarchy,
  hierarchies,
  moveTerritoriesTGTypes,
  selectedNodes,
  setOpenedModal,
  onOpenMap,
  onCloseMap,
  isFilterPresent
}: TerritoryGridActionButtonsProps) => {
  const { userRole, userMembershipSpecs } = useUser();
  const { selectedBattleCardId, battleCardLookupMap } = useBattleCard();
  const {
    showActivities,
    showAggregatedActivities,
    setShowUpsertTerritoryRuleView,
    showUpsertTerritoryRuleView,
    isBulkDeleteChecked
  } = useGrid();
  const { selectedTenant } = useScope();

  const { showUploadSequenceByFileType, setShowUploadSequenceByFileType } = useFileUpload();
  const { isDataTrayMapOpen } = useMapVariant();
  const [isBulkDeleteTerOn] = useTreatment(SplitFeatures.BULK_DELETION_TERRITORIES);
  const [isAddedConfigurationsForUserPermissionsOn] = useTreatment(
    SplitFeatures.ADDED_CONFIGURATIONS_FOR_USER_PERMISSIONS
  );
  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const isRollUpBattleCard = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType === BattlecardType.Rollup;
  const battleCardOwner = battleCardLookupMap?.[selectedBattleCardId]?.owner?.memberId;

  const userMemberId = userMembershipSpecs?.find(
    (membershipSpec) => membershipSpec.tenantId === selectedTenant?.id
  )?.memberId;

  const isCurrentUserBattleCardOwner =
    battleCardOwner != null && userMemberId != null && battleCardOwner.toString() === userMemberId.toString();

  const userPermissionForAdding =
    isAddedConfigurationsForUserPermissionsOn && isCurrentUserBattleCardOwner
      ? UserAction.TERRITORY_DEFINITION_AND_ASSIGNMENT_EDIT
      : UserAction.TERRITORY_DEFINITION_MODIFY;

  const canEditTerritory = useCanUser(UserAction.TERRITORY_DEFINITION_AND_ASSIGNMENT_EDIT);
  const hasPermissionToAdd = useCanUser(userPermissionForAdding);

  const canUserAddContent = isAddedConfigurationsForUserPermissionsOn
    ? canEditTerritory && isCurrentUserBattleCardOwner
    : // eslint-disable-next-line no-restricted-syntax
      userRole !== UserRoleType.CONTRIBUTOR;

  const shouldShowGroupByButton = showAggregatedActivities && hierarchies;
  const shouldShowMoveTerritoriesButton =
    selectedBattleCardId &&
    !(isDataTrayMapOpen || showActivities || showAggregatedActivities || showUpsertTerritoryRuleView) &&
    canUserAddContent;
  const shouldShowAddButton = isAddedConfigurationsForUserPermissionsOn
    ? canUserAddContent
    : !showUpsertTerritoryRuleView;

  const shouldShowBulkDeleteTerritoryButton =
    isBulkDeleteTerOn && selectedBattleCardId && !isDataTrayMapOpen && canUserAddContent;

  const shouldShowMapToggle =
    selectedBattleCardId && !(showAggregatedActivities || showActivities || showUpsertTerritoryRuleView);

  const MoveTerritoryBtn = (
    <MoveTerritoryButton
      onClick={() => setOpenedModal(PlanningCycleModal.MOVE_TERRITORY)}
      disabled={moveTerritoriesTGTypes.length !== 1 || !selectedNodes?.length}
    />
  );

  const isBulkDeleteDisabled = isFilterPresent && isBulkDeleteChecked;

  return (
    <>
      {shouldShowGroupByButton && (
        <>
          <span>{formatMessage('GROUP_BY')}</span>
          <div className={b('hierarchyButtons')} data-testid="hierarchy-buttons">
            {hierarchies?.getRootHierarchies?.map((hierarchy) => (
              <div
                key={hierarchy.rootHierarchyId}
                data-testid={`hierarchy-button-${hierarchy.rootHierarchyId}`}
                onClick={() =>
                  setSelectedHierarchy((previousSelect) => {
                    if (hierarchy.rootKey === previousSelect) {
                      // if click on the currently selected one, deselect it. calling blur to reset focus,
                      // so that after deselect the button reset to it's default css style
                      (document.activeElement as HTMLElement).blur();
                      return '';
                    } else {
                      return hierarchy.rootKey;
                    }
                  })
                }
              >
                <TextButton
                  type="button"
                  text={hierarchy.rootName}
                  intent={hierarchy.rootKey === selectedHierarchy ? 'primary' : null}
                  className={'hierarchyButton'}
                  testId={'sort-by-hierarchy-button'}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {shouldShowMoveTerritoriesButton && (
        <div>
          {moveTerritoriesTGTypes.length !== 1 && selectedNodes?.length ? (
            <MessageTooltip
              target={MoveTerritoryBtn}
              content={formatMessage('MOVE_TERRITORY_DISABLED')}
              placement={'top'}
            />
          ) : (
            MoveTerritoryBtn
          )}
        </div>
      )}
      {shouldShowAddButton && hasPermissionToAdd && (
        <DataTrayAddButton
          menuItems={[
            <MenuItem
              text={formatMessage('ADD_TERRITORY_ROW')}
              key="addTerritoryRow"
              onClick={() => setShowUpsertTerritoryRuleView(true)}
              data-testid="add-territory-row-menu-item"
            />,
            <MenuItem
              text={formatMessage('LOAD_TERRITORIES')}
              key="loadTerritories"
              onClick={() =>
                setShowUploadSequenceByFileType({
                  ...showUploadSequenceByFileType,
                  [FileType.TERRITORY_RULE_PLAN]: true
                })
              }
              data-testid="load-territories-menu-item"
            />
          ]}
          disabled={!selectedBattleCardId || isRootBattleCard || isRollUpBattleCard}
        />
      )}
      {shouldShowBulkDeleteTerritoryButton && (
        <BulkDeleteTerritoriesButton
          onClick={() => {
            setOpenedModal(PlanningCycleModal.BULK_DELETE_TERRITORY);
          }}
          disabled={isBulkDeleteDisabled || !selectedNodes?.length}
          showTooltip={isBulkDeleteDisabled}
        />
      )}
      {shouldShowMapToggle && <MapGridToggle onOpenMap={onOpenMap} onCloseMap={onCloseMap} />}
    </>
  );
};

export default TerritoryGridActionButtons;
