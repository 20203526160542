import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ChevronDown, Edit, Save } from '@carbon/icons-react';
import { Button, DividerV2, Text, CalloutV2, Intent } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { CustomHierarchyFilterMenuV2 } from 'app/components/TerritoryMap/CustomHierarchyFilterMenuV2';
import SegmentPicker from 'app/components/TerritoryMap/TerritoryMapGrid/SegmentPicker';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import {
  CollectionFilter,
  SegmentEditorDialogModes,
  Segment,
  CollectionFilterKind,
  NamedRootHierarchy,
  HierarchyType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapGridFilterV2.module.pcss';

const b = block(style);

interface MapGridFilterProps {
  customHierarchyFilter: CollectionFilter<number>[];
  segments: Segment[];
  showCollapseAndExpandButtons: boolean;
  customHierarchies: NamedRootHierarchy[];
}
const MapGridFilterV2: React.FC<MapGridFilterProps> = ({
  customHierarchyFilter: remoteFilter,
  segments,
  showCollapseAndExpandButtons = false,
  customHierarchies
}) => {
  const { selectedSegmentId, setSegmentEditorDialogOptions, setSelectedSegmentId } = useDedicatedMapProvider();
  const [isOpen, setIsOpen] = useState(false);
  const [filterDraft, setFilterDraft] = useState(remoteFilter);
  const [isFormError, setIsFormError] = useState(false);
  const { rootHierarchies } = useMapContextRedistributor();

  const customRootHierarchies = useMemo(
    () => rootHierarchies.filter((rootHierarchy) => rootHierarchy.hierarchyType === HierarchyType.CustomHierarchy),
    [rootHierarchies]
  );

  const getDefaultFilter = () => {
    const defaultFilter = customRootHierarchies.map((rootHierarchy) => ({
      rootHierarchyId: rootHierarchy.rootHierarchyId,
      ids: [],
      kind: CollectionFilterKind.EQUALS
    }));

    return defaultFilter;
  };

  useEffect(() => {
    if (remoteFilter.length === 1 && !remoteFilter[0].rootHierarchyId) {
      setFilterDraft(getDefaultFilter());
      applyFilter(getDefaultFilter(), null, false);
    }
  }, [customHierarchies]);

  useEffect(() => {
    setFilterDraft(remoteFilter);
  }, [remoteFilter]);

  const selectedSegment = useMemo(
    () => segments.find((segment) => segment.segmentId === selectedSegmentId),
    [segments, selectedSegmentId]
  );

  const showWarningCallout = filterDraft?.some(
    (filter) => filter.kind !== CollectionFilterKind.EQUALS && filter.kind !== CollectionFilterKind.NONE
  );

  const clearFilter = () => {
    setFilterDraft(getDefaultFilter());
    applyFilter(getDefaultFilter(), null, false);
  };

  const postMessage = useMapWorkerPostMessage();

  const applyFilter = (
    filter: CollectionFilter<number>[],
    segmentId: number | null,
    appliedFromForm: boolean
  ): boolean => {
    const error = filter.some(
      (currentFilter) =>
        currentFilter.ids.length === 0 &&
        currentFilter.kind !== CollectionFilterKind.NONE &&
        currentFilter.kind !== CollectionFilterKind.MATCH_ANY
    );

    if (error && appliedFromForm) {
      setIsFormError(true);
      return false;
    }
    setIsFormError(false);
    setSelectedSegmentId(segmentId);
    postMessage({ type: 'custom-hierarchy-filter-change', filter });

    return true;
  };

  const updateFilterDraft = (rootHierarchyFilter: CollectionFilter<number>) => {
    setFilterDraft((prevFilter) =>
      prevFilter.map((prevRootHierarchyfilter) =>
        prevRootHierarchyfilter.rootHierarchyId === rootHierarchyFilter.rootHierarchyId
          ? rootHierarchyFilter
          : prevRootHierarchyfilter
      )
    );
  };

  const getCustomHierarchiesInRootHierarchy = useCallback(
    (rootHierarchyId: number): NamedRootHierarchy[] => {
      const customHierarchiesInRootHierarchy: NamedRootHierarchy[] = [];

      customHierarchies.forEach((customHierarchy) => {
        if (customHierarchy.rootHierarchyId === rootHierarchyId) {
          customHierarchiesInRootHierarchy.push({
            customHierarchyId: customHierarchy.customHierarchyId,
            customHierarchyName: customHierarchy.customHierarchyName,
            rootHierarchyId: customHierarchy.rootHierarchyId,
            rootHierarchyName: customHierarchy.rootHierarchyName
          });
        }
      });

      return customHierarchiesInRootHierarchy;
    },
    [customHierarchies]
  );

  const handleClose = () => {
    const isFilterApplied = applyFilter(filterDraft, selectedSegmentId, true);

    if (isFilterApplied) setIsOpen(false);
  };

  const handleApplyButtonClicked = useCallback(() => {
    applyFilter(filterDraft, selectedSegmentId, true);
  }, [filterDraft, selectedSegmentId]);

  return (
    <Popover
      isOpen={isOpen}
      onOpened={() => setIsOpen(true)}
      onClosed={handleClose}
      onInteraction={(nextOpenState) => {
        if (!nextOpenState) handleClose();
      }}
      minimal
      placement="bottom-end"
      content={
        <div className={b('filterSelectionWrapper')}>
          <Text className={b('filterMapsTitle')}>{formatMessage('FILTER_MAPS_BY')}</Text>
          <SegmentPicker
            segments={segments}
            selectedSegmentId={selectedSegmentId}
            onApplySegment={(filter, segmentId) => {
              setFilterDraft(filter);
              applyFilter(filter, segmentId, false);
            }}
            onChooseCreateBlank={() => {
              handleClose();
              setSegmentEditorDialogOptions({
                mode: SegmentEditorDialogModes.CREATE,
                filter: getDefaultFilter()
              });
            }}
          />
          {selectedSegmentId === null && (
            <div className={b('customHierarchyFilterMenuWrapper')}>
              <DividerV2 />
              {customRootHierarchies.map((rootHierarchy) => (
                <div key={rootHierarchy.rootHierarchyId} className={b('customHierarchyFilter')}>
                  <CustomHierarchyFilterMenuV2
                    filter={filterDraft.find((filter) => filter.rootHierarchyId === rootHierarchy.rootHierarchyId)}
                    rootHierarchyName={rootHierarchy.rootName}
                    rootHierarchyId={rootHierarchy.rootHierarchyId}
                    hierarchies={getCustomHierarchiesInRootHierarchy(rootHierarchy.rootHierarchyId)}
                    onUpdateFilter={(rootHierarchyfilter) => {
                      updateFilterDraft(rootHierarchyfilter);
                    }}
                    isFormError={isFormError}
                  />
                </div>
              ))}
              {showWarningCallout && (
                <div className={b('filterCallout')}>
                  <CalloutV2 intent={Intent.PRIMARY} hideActionText={true} data-testid="filter-callout">
                    {formatMessage('MAP_CUSTOM_HIERARCHY_FILTER_CALLOUT_MESSAGE')}
                  </CalloutV2>
                </div>
              )}
            </div>
          )}
          <div className={b('filterFooter')}>
            <div className={b('saveSegment')}>
              {selectedSegment ? (
                <IconButton
                  testId="edit-segment-button"
                  icon={<Edit />}
                  onClick={() => {
                    setSegmentEditorDialogOptions({
                      mode: SegmentEditorDialogModes.EDIT,
                      segment: selectedSegment,
                      filter: filterDraft
                    });
                    handleClose();
                  }}
                  tooltipText={formatMessage('EDIT_SEGMENT')}
                  minimal
                  type="button"
                  tooltipPlacement="bottom"
                />
              ) : (
                <IconButton
                  testId="save-segment-button"
                  icon={<Save />}
                  onClick={() => {
                    setSegmentEditorDialogOptions({ mode: SegmentEditorDialogModes.CREATE, filter: filterDraft });
                    handleClose();
                  }}
                  tooltipText={formatMessage('SAVE_SEGMENT')}
                  minimal
                  type="button"
                  tooltipPlacement="bottom"
                />
              )}
            </div>
            <div className={b('filterApplyAndClear')}>
              <Button
                minimal
                text={formatMessage('CLEAR_ALL')}
                onClick={clearFilter}
                data-testid="clear-filter-button"
              />
              <Button
                className={b('applyFilterButton')}
                text={formatMessage('APPLY')}
                onClick={handleApplyButtonClicked}
                data-testid="apply-filter-button"
              />
            </div>
          </div>
        </div>
      }
    >
      <TextButton
        className={b('filterButton', { isSmall: showCollapseAndExpandButtons })}
        text={selectedSegment?.segmentName || formatMessage('HIERARCHIES')}
        type="button"
        minimal
        large={false}
        rightIcon={<ChevronDown />}
        testId="filter-button"
        onClick={() => setIsOpen((prevIsMapGridFilterOpen) => !prevIsMapGridFilterOpen)}
        active={isOpen}
      />
    </Popover>
  );
};

export default MapGridFilterV2;
