import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  CellClickedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  SortChangedEvent
} from '@ag-grid-community/core';
import { InputGroup } from '@blueprintjs/core';
import { Close, Search } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Icon from 'components/Icon/Icon';

import { MapIgnoreCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/MapIgnoreCellRenderer/MapIgnoreCellRenderer';
import { MapLockCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/MapLockCellRenderer/MapLockCellRenderer';
import { TerritoryMapOmniCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/TerritoryMapOmniCellRenderer/TerritoryMapOmniCellRenderer';
import ExperimentalBasicGrid from 'app/components/AdvancedGrid/ExperimentalBasicGrid';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { SplitFeatures } from 'app/global/features';
import { TERRITORY_MAP_GRID_WIDTH } from 'app/global/variables';

import { SortDirection } from 'app/graphql/generated/graphqlApolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import {
  CollectionFilter,
  MchQuantity,
  RuleForMap,
  TerritoryGroupForMap,
  Segment,
  NamedRootHierarchy,
  CollectionFilterKind
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { useLockAndIgnoreActions } from './hooks/useLockAndIgnoreActions';
import { useMapGridSortOrder } from './hooks/useMapGridSortOrder';
import { usePrimaryTerritoryField } from './hooks/usePrimaryTerritoryField';
import EmptyFilterMessage from './TerritoryMapGrid/EmptyFilterMessage';
import { EmptyMapGridMessage } from './TerritoryMapGrid/EmptyMapGridMessage';
import MapGridHeader from './TerritoryMapGrid/MapGridHeader';
import SegmentCards from './TerritoryMapGrid/SegmentCards';
import SegmentCardsLoadingIndicator from './TerritoryMapGrid/SegmentCardsLoadingIndicator';
import { IgnoreHeader, LockHeader } from './TerritoryMapGridHeaders';
import style from './TerritoryMapGridV2.module.pcss';

const b = block(style);

export interface TmgTerritoryRow {
  rule: RuleForMap;
  territoryNameAndId: string;
  isLocked: boolean;
  isIgnored: boolean;
  groupForLevel: TerritoryGroupForMap | null;
}

export interface TmgTerritoryGroupRow {
  isLocked: boolean;
  isIgnored: boolean;

  // These fields are guaranteed to be undefined,
  // specifying them as such means we don't have to do explicit checks for them in the union type
  rule: undefined;
  groupForLevel: undefined;
  territoryNameAndId: undefined;
}

export type TmgRow = TmgTerritoryGroupRow | TmgTerritoryRow;
type WithTmgRow<T> = Omit<T, 'data'> & { data: TmgRow };

export interface TerritoryMapGridV2Props {
  territoryRules: RuleForMap[];
  loading: boolean;
  lockedRuleIds: Set<number>;
  ignoredRuleIds: Set<number>;
  selectedRuleIds: number[];
  isSelectionEnabled: boolean;
  customHierarchies: NamedRootHierarchy[];
  customHierarchyFilter: CollectionFilter<number>[];
  onSelectRules: (ruleIds: number[]) => void;
  onRecolorFinish?: () => void;
  segments: Segment[];
  isMapLoading: boolean;
}

export const TerritoryMapGridV2: FC<TerritoryMapGridV2Props> = ({
  territoryRules,
  lockedRuleIds,
  ignoredRuleIds,
  selectedRuleIds,
  loading,
  isSelectionEnabled,
  customHierarchies,
  customHierarchyFilter,
  onSelectRules,
  onRecolorFinish,
  isMapLoading,
  segments
}) => {
  const { groupLookup, territoryGroupLevel, chosenCustomHierarchy, selectedSegmentId } = useDedicatedMapProvider();
  const [isMapSegmentationEnabled] = useTreatment(SplitFeatures.MAP_SEGMENTATION);
  const [primaryTerritoryField, setPrimaryTerritoryField] = usePrimaryTerritoryField();
  const [sortOrder, setSortOrder] = useMapGridSortOrder();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const handleOnGridReady = useCallback((e: GridReadyEvent) => {
    setColumnApi(e.columnApi);
    setGridApi(e.api);
  }, []);

  const gridData = useMemo(() => {
    const combinerMessageKey =
      primaryTerritoryField === 'territoryId' ? 'TERRITORY_ID_AND_NAME' : 'TERRITORY_NAME_AND_ID';
    return territoryRules
      .filter((rule) => rule.isPassingFilter)
      .map(
        (rule): TmgTerritoryRow => ({
          rule,
          groupForLevel: groupLookup.get(rule.territoryGroupId),
          isLocked: lockedRuleIds.has(rule.ruleId),
          isIgnored: ignoredRuleIds.has(rule.ruleId),
          territoryNameAndId: formatMessage(combinerMessageKey, {
            territoryId: rule.territoryId,
            territoryName: rule.territoryName
          })
        })
      );
  }, [territoryRules, groupLookup, primaryTerritoryField]);

  useEffect(() => {
    if (!gridApi) return;
    const updatedRows = new Array<TmgTerritoryRow>();
    gridApi.forEachLeafNode((node: WithTmgRow<RowNode>) => {
      const ruleId = node.data.rule.ruleId;
      if (node.data.isLocked !== lockedRuleIds.has(ruleId) || node.data.isIgnored !== ignoredRuleIds.has(ruleId))
        updatedRows.push({ ...node.data, isLocked: lockedRuleIds.has(ruleId), isIgnored: ignoredRuleIds.has(ruleId) });
    });
    if (updatedRows.length > 0) {
      gridApi.applyTransaction({ update: updatedRows });
      gridApi.refreshCells({ force: true });
      gridApi.refreshHeader();
    }
  }, [gridApi, gridData, lockedRuleIds, ignoredRuleIds]);

  const autoGroupColumnDef = useMemo(
    (): ColDef => ({
      colId: 'GroupingColumn',
      field: 'territoryNameAndId',
      flex: 1,
      sortable: true,
      sort: sortOrder,
      sortingOrder: [SortDirection.asc, SortDirection.desc],
      comparator: compareWithMappableOnTop,
      cellClass: ({ node }) => (node.group && node.aggData?.isIgnored ? b('ignoredGroup') : ''),
      headerValueGetter: (params) => {
        let count = 0;
        params.api?.forEachNodeAfterFilter((node) => {
          if (!node.group) count += 1;
        });
        if (count === 0) return formatMessage('TERRITORIES');
        return formatMessage('TERRITORIES_AND_COUNT', { count });
      },
      cellRendererSelector: (params) =>
        params.node.group
          ? { component: 'agGroupCellRenderer' }
          : { frameworkComponent: TerritoryMapOmniCellRenderer, params: { onRecolorFinish } }
    }),
    [onRecolorFinish, sortOrder]
  );

  const { toggleLockRules, toggleIgnoreRules, toggleLockAll, toggleIgnoreAll } = useLockAndIgnoreActions();

  const columnDefs = useMemo((): ColDef[] => {
    return [
      {
        // This ColDef determines how groups are created, not how they look
        rowGroup: true,
        hide: true,
        valueGetter: (params: { data?: TmgRow }) => params.data?.groupForLevel?.name ?? null
      },
      {
        colId: 'isLocked',
        field: 'isLocked',
        headerComponentFramework: LockHeader,
        headerComponentParams: { onClick: toggleLockAll },
        headerClass: b('iconCell'),
        cellClass: b('iconCell'),
        valueGetter: (params: { data?: TmgRow }) => params.data?.isLocked,
        aggFunc: ({ values }) => values?.length && !!values.every(Boolean),
        width: ICON_COLUMN_WIDTH,
        cellRendererFramework: MapLockCellRenderer
      },
      {
        colId: 'isIgnored',
        field: 'isIgnored',
        headerComponentFramework: IgnoreHeader,
        headerComponentParams: { onClick: toggleIgnoreAll },
        headerClass: b('iconCell'),
        cellClass: b('iconCell'),
        valueGetter: (params: { data?: TmgRow }) => params.data?.isIgnored,
        aggFunc: ({ values }) => values?.length && !!values.every(Boolean),
        width: ICON_COLUMN_WIDTH,
        cellRendererFramework: MapIgnoreCellRenderer
      }
    ];
  }, [toggleLockAll, toggleIgnoreAll]);

  const handleGridSortChanged = useCallback((sortChangedEvent: SortChangedEvent) => {
    const newSort = sortChangedEvent.columnApi.getColumnState().find((column) => column.sort);
    setSortOrder(newSort.sort as SortDirection);
  }, []);

  const handleSortOrderChanged = useCallback(
    (newOrder: SortDirection) => {
      if (!columnApi || !gridApi) return;
      const colId = columnApi.getColumnState().find((column) => column.sort).colId;
      columnApi.applyColumnState({
        state: [{ colId, sort: newOrder }],
        applyOrder: true
      });

      gridApi.onSortChanged();
      gridApi.refreshHeader();
    },
    [columnApi, gridApi]
  );

  const handleCellClicked = useCallback(
    (event: WithTmgRow<CellClickedEvent>) => {
      const individualRuleId = event.data?.rule?.ruleId;
      const childRuleIds = event.node.childrenAfterGroup?.map((child) => child.data.rule.ruleId);
      const ruleIds = individualRuleId ? [individualRuleId] : childRuleIds;
      if (!ruleIds) return;
      switch (event.colDef.colId) {
        case 'isLocked':
          toggleLockRules(ruleIds);
          break;
        case 'isIgnored':
          toggleIgnoreRules(ruleIds);
          break;
        default:
          if (isSelectionEnabled && individualRuleId) event.node.setSelected(!event.node.isSelected(), false, false);
          break;
      }
    },
    [toggleLockRules, toggleIgnoreRules, isSelectionEnabled]
  );

  const searchText = useRef('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const setSearchText = (text: string) => {
    searchText.current = text;
    gridApi.onFilterChanged();
    gridApi.refreshHeader();
  };
  const handleOpenSearch = () => setIsSearchOpen(true);
  const clearAndCloseSearch = () => {
    setIsSearchOpen(false);
    setSearchText('');
  };
  const isFilterPresent = useCallback(() => !!searchText.current, []);
  const doesFilterPass = useCallback((node: WithTmgRow<RowNode>): boolean => {
    if (!searchText.current) return true;
    if (!node.data) return false;
    const query = searchText.current.toLowerCase() as Lowercase<string>;
    return includesQuery(query, node.data.territoryNameAndId) || includesQuery(query, node.data.groupForLevel?.name);
  }, []);

  const applyRuleSelectionToGrid = useCallback(() => {
    if (!gridApi) return;
    const selectedRuleIdSet = new Set(selectedRuleIds);
    gridApi.forEachNode((node: WithTmgRow<RowNode>) => {
      const ruleId = node.data?.rule?.ruleId;
      if (!ruleId) return;
      const isNodeSelected = selectedRuleIdSet.has(ruleId);
      node.setSelected(isNodeSelected, false, true);
    });
    gridApi.refreshHeader();
  }, [gridApi, selectedRuleIds]);

  useEffect(() => {
    applyRuleSelectionToGrid();
  }, [applyRuleSelectionToGrid]);

  const handleSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedNodes();
    const selectedRuleIds: number[] = selectedRows
      .map((row: { data: TmgRow }) => row.data?.rule?.ruleId)
      .filter(Boolean);
    onSelectRules(selectedRuleIds);
  }, []);

  useEffect(() => {
    if (!gridApi) return;
    if (loading) {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [loading, gridApi]);

  const handleCollapseAll = () => gridApi?.collapseAll();
  const handleExpandAll = () => gridApi?.expandAll();

  const isFilterEmpty = customHierarchyFilter.every(
    (filter) => filter.kind === CollectionFilterKind.EQUALS && filter.ids.length === 0
  );

  const isMapSegmentsVisible =
    isMapSegmentationEnabled &&
    segments.length > 0 &&
    isFilterEmpty &&
    chosenCustomHierarchy.quantity === MchQuantity.ANY &&
    !isMapLoading &&
    selectedSegmentId === null;

  const isMapSegmentsLoading =
    isMapSegmentationEnabled &&
    isMapLoading &&
    chosenCustomHierarchy.quantity === MchQuantity.ANY &&
    selectedSegmentId === null;

  const isEmptyStateVisible =
    isMapSegmentationEnabled &&
    !isMapLoading &&
    segments.length === 0 &&
    isFilterEmpty &&
    chosenCustomHierarchy.quantity === MchQuantity.ANY;

  const isGridVisible =
    !isMapSegmentationEnabled ||
    (chosenCustomHierarchy.quantity !== MchQuantity.SINGULAR && chosenCustomHierarchy.quantity !== MchQuantity.ANY) ||
    !isFilterEmpty;

  return (
    <div className={b()} data-testid="territory-map-grid">
      {isSearchOpen ? (
        <InputGroup
          onChange={(event) => setSearchText(event.target?.value ?? '')}
          placeholder={formatMessage('SEARCH')}
          leftIcon={<Icon icon={<Search />} />}
          data-testid="map-grid-search-input"
          className={b('searchInput')}
          rightElement={
            <IconButton
              onClick={clearAndCloseSearch}
              icon={<Close size={12} />}
              type="button"
              testId="close-search-button"
              small
            />
          }
        />
      ) : (
        <MapGridHeader
          customHierarchyFilter={customHierarchyFilter}
          customHierarchies={customHierarchies}
          onSearchIconClick={handleOpenSearch}
          onCollapseRows={handleCollapseAll}
          onExpandRows={handleExpandAll}
          showCollapseAndExpandButtons={territoryGroupLevel !== null}
          primaryTerritoryField={primaryTerritoryField}
          onPrimaryTerritoryFieldChange={setPrimaryTerritoryField}
          sortOrder={sortOrder}
          onSortOrderChange={handleSortOrderChanged}
          segments={segments}
        />
      )}
      {isMapSegmentsVisible && (
        <SegmentCards
          customHierarchyFilter={customHierarchyFilter}
          segments={segments}
          customHierarchies={customHierarchies}
          territoryRules={territoryRules}
        />
      )}

      {isEmptyStateVisible && <EmptyFilterMessage territoryCount={territoryRules.length} />}

      {isMapSegmentsLoading && <SegmentCardsLoadingIndicator />}

      <ExperimentalBasicGrid
        className={b('basicGrid', { hidden: !isGridVisible })}
        rowData={loading ? null : gridData}
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        loadingOverlayComponentFramework={LoadingOverlayRenderer}
        noRowsOverlayComponentFramework={EmptyMapGridMessage}
        groupDefaultExpanded={ALL_ROWS_EXPANDED}
        rowHeight={MAP_GRID_ROW_HEIGHT}
        groupHeaderHeight={MAP_GRID_ROW_HEIGHT}
        headerHeight={MAP_GRID_HEADER_HEIGHT}
        groupDisplayType="singleColumn"
        rowSelection="multiple"
        data-testid="territory-groups-map-grid"
        onCellClicked={handleCellClicked}
        isExternalFilterPresent={isFilterPresent}
        doesExternalFilterPass={doesFilterPass}
        onSelectionChanged={handleSelectionChanged}
        onGridReady={handleOnGridReady}
        getRowNodeId={getRowNodeId}
        onRowDataChanged={applyRuleSelectionToGrid}
        suppressAnimationFrame={false}
        animateRows
        suppressCellSelection
        suppressRowClickSelection
        suppressAggFilteredOnly
        onSortChanged={handleGridSortChanged}
      />
    </div>
  );
};

const getRowNodeId = (data: TmgTerritoryRow) => `R::${data.rule.ruleId}`;

const LoadingOverlayRenderer = React.memo(() => (
  <GridLoading gridHeight={window.innerHeight} gridWidth={TERRITORY_MAP_GRID_WIDTH} />
));

const includesQuery = (query: Lowercase<string>, text: string | null | undefined) =>
  text?.toLowerCase().includes(query);

const compareWithMappableOnTop = (
  valueA: string,
  valueB: string,
  nodeA: WithTmgRow<RowNode>,
  nodeB: WithTmgRow<RowNode>,
  isInverted: boolean
) => {
  const lexicoOrder = valueA.localeCompare(valueB);
  if (!nodeA.data?.rule || !nodeB.data?.rule) return lexicoOrder;
  if (nodeA.data.rule.isMappable === nodeB.data.rule.isMappable) return lexicoOrder;
  // Mappable rules should be sorted first, regardless of invert
  const mappableOrder = nodeA.data.rule.isMappable ? -1 : 1;
  return isInverted ? -mappableOrder : mappableOrder;
};

export const MAP_GRID_ROW_HEIGHT = 28;
export const MAP_GRID_HEADER_HEIGHT = 32;
const ICON_COLUMN_WIDTH = MAP_GRID_ROW_HEIGHT;

// Magic number from AG Grid;
const ALL_ROWS_EXPANDED = -1;
