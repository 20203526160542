import React, { useMemo } from 'react';

import { ArrowRight } from '@carbon/icons-react';
import { Spinner, Intent } from '@varicent/components';
import { Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';

import { useGetRootCustomHierarchiesInfo } from 'app/components/TerritoryMap/hooks/useGetRootCustomHierarchies';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import {
  AccountSourceFilterEnum,
  CustomHierarchyQuantifierEnum,
  VisualizationTerritoryGroupTypeEnum
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useUpsertBattleCard } from 'app/graphql/mutations/upsertBattlecard';
import { GET_MAP_VISUALIZATION_SETTINGS } from 'app/graphql/queries/getMapVisualizationSettings';
import { useGetTerritoryGroupTypesForMap } from 'app/graphql/queries/getTerritoryGroupTypesForMap';

import useTreatment from 'app/hooks/useTreatment';

import { HierarchyType, MchQuantity, MchQuantityType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapVisualizationSettingsDialog.module.pcss';
import MapVisualizationSettingsForm from './MapVisualizationSettingsForm';

const b = block(style);

interface MapVisualizationSettingsDialogProps {
  onClose: () => void;
  onComplete: () => void;
  isLastStep: boolean;
}

export interface FormValues {
  territoryGroupType: string;
  quantity: MchQuantityType;
  customHierarchyRoot: number;
  accountSourceFilter: AccountSourceFilterEnum;
}

const MapVisualizationSettingsDialog: React.FC<MapVisualizationSettingsDialogProps> = ({
  onClose,
  onComplete,
  isLastStep
}) => {
  const { selectedBattleCardId } = useBattleCard();
  const { selectedDeploymentModelId } = useScope();
  const { primaryHierarchy, accountSourceFilter } = useMapContextRedistributor();
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const [isSingleCustomHierarchyMapOn] = useTreatment(SplitFeatures.MAP_SINGLE_CUSTOM_HIERARCHY);
  const { rootCustomHierarchies } = useGetRootCustomHierarchiesInfo(!isSingleCustomHierarchyMapOn);
  const [isMapSegmentationEnabled] = useTreatment(SplitFeatures.MAP_SEGMENTATION);

  const [upsertBattlecard, { loading: upsertBattlecardLoading }] = useUpsertBattleCard({
    onCompleted() {
      onComplete();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const { data: battleCardData, loading: battleCardLoading } = useGetTerritoryGroupTypesForMap({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        battlecardId: +selectedBattleCardId,
        isTQM: false,
        deploymentModelId: selectedDeploymentModelId
      }
    }
  });

  const mappableTerritoryGroupTypes = useMemo(() => {
    return (
      battleCardData?.getDeploymentModelSpec.battlecards[0].territoryGroupTypes.filter(
        (tgt) =>
          tgt.hierarchyType === HierarchyType.CustomerAccountHierarchy ||
          tgt.hierarchyType === HierarchyType.GeographicTerritoryHierarchy
      ) ?? []
    );
  }, [battleCardData]);

  const territoryGroupTypeItems = mappableTerritoryGroupTypes.map((tgt) => ({
    text: tgt.hierarchyRootName,
    value: tgt.hierarchyType as HierarchyType
  }));

  const customHierarchyItems = useMemo(() => {
    return rootCustomHierarchies.map((root) => ({ text: root.rootName, value: root.rootHierarchyId }));
  }, [rootCustomHierarchies]);

  const handleSubmit = (values: FormValues) => {
    const quantity =
      isMapSegmentationEnabled && values.quantity !== MchQuantity.NONE ? MchQuantity.ANY : values.quantity;

    const vizSettings = {
      territoryGroupType: values.territoryGroupType as VisualizationTerritoryGroupTypeEnum,
      customHierarchyQuantifier: quantity as CustomHierarchyQuantifierEnum,
      customHierarchyId:
        values.customHierarchyRoot && values.quantity !== CustomHierarchyQuantifierEnum.NONE
          ? values.customHierarchyRoot
          : null
    };
    const shouldUpdateAccountFilter =
      values.territoryGroupType === VisualizationTerritoryGroupTypeEnum.CustomerAccountHierarchy;

    upsertBattlecard({
      variables: {
        input: {
          battlecardId: +selectedBattleCardId,
          deploymentModelId: selectedDeploymentModelId,
          mapVisualizationSettings: vizSettings,
          accountSourceFilter: shouldUpdateAccountFilter ? values.accountSourceFilter : null
        }
      },
      refetchQueries: [GET_MAP_VISUALIZATION_SETTINGS],
      awaitRefetchQueries: true
    });
  };

  const initialCustomHierarchyRoot =
    chosenCustomHierarchy.quantity === MchQuantity.SINGULAR
      ? chosenCustomHierarchy.details.rootHierarchyId
      : customHierarchyItems[0]?.value;
  const initialFormValues: FormValues = {
    territoryGroupType: primaryHierarchy,
    quantity: chosenCustomHierarchy.quantity,
    customHierarchyRoot: initialCustomHierarchyRoot ?? null,
    accountSourceFilter
  };

  return (
    <Formik initialValues={initialFormValues} enableReinitialize={true} onSubmit={handleSubmit}>
      {({ values }) => (
        <Dialog
          portalClassName={b('dialog')}
          isOpen
          title={formatMessage('MAP_VISUALIZATION_SETTINGS_TITLE')}
          onClose={onClose}
          confirmButtonText={isLastStep ? formatMessage('MAP') : formatMessage('NEXT')}
          onSubmit={() => handleSubmit(values)}
          confirmButtonLoading={upsertBattlecardLoading}
          confirmButtonIcon={isLastStep ? null : <ArrowRight />}
        >
          {!battleCardLoading ? (
            <div className={b('dialogContainer')}>
              <MapVisualizationSettingsForm
                territoryGroupTypeItems={territoryGroupTypeItems}
                customHierarchyItems={customHierarchyItems}
              />
            </div>
          ) : (
            <div data-testid="spinner" className={b('spinnerContainer')}>
              <Spinner intent={Intent.PRIMARY} />
            </div>
          )}
        </Dialog>
      )}
    </Formik>
  );
};

export default MapVisualizationSettingsDialog;
