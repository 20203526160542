import React, { useCallback, useMemo } from 'react';

import { ArrowRight, Edit, OverflowMenuHorizontal, TrashCan } from '@carbon/icons-react';
import { CalloutV2, Card, Classes, Intent, Text } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import { Menu } from 'components/menu/Menu';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import ExpandableCustomHierarchyTags from 'app/components/TerritoryMap/TerritoryMapGrid/ExpandableCustomHierarchyTags';
import OrphanedHierarchiesCallout from 'app/components/TerritoryMap/TerritoryMapGrid/OrphanedHierarchiesCallout';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { CollectionFilter, NamedRootHierarchy, SegmentEditorDialogModes, SegmentFilterClause } from 'app/models';

import block from 'utils/bem-css-modules';
import { segmentClauseToCollectionFilter } from 'utils/helpers/segmentFilterUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './SegmentCard.module.pcss';

const b = block(style);

export interface SegmentCardProps {
  name: string;
  id: number;
  territoriesCount: number | null;
  isLoadingTerritoriesCount: boolean;
  filter: SegmentFilterClause[];
  customHierarchies: NamedRootHierarchy[];
  orphanCount: number;
  customHierarchyFilter: CollectionFilter<number>[];
}

const SegmentCard: React.FC<SegmentCardProps> = ({
  name,
  territoriesCount,
  id,
  filter,
  customHierarchies,
  isLoadingTerritoriesCount,
  orphanCount,
  customHierarchyFilter
}) => {
  const postMessage = useMapWorkerPostMessage();
  const { setSegmentEditorDialogOptions } = useDedicatedMapProvider();
  const { setSelectedSegmentId } = useDedicatedMapProvider();

  // Todo: Multi clause maps remove [0]
  const segmentAsFilter = useMemo(() => segmentClauseToCollectionFilter(filter[0]), [filter]);

  const applySegment = useCallback(() => {
    const newFilter = customHierarchyFilter.map((prevRootHierarchyfilter) =>
      prevRootHierarchyfilter.rootHierarchyId === segmentAsFilter.rootHierarchyId
        ? segmentAsFilter
        : prevRootHierarchyfilter
    );
    setSelectedSegmentId(id);

    postMessage({ type: 'custom-hierarchy-filter-change', filter: newFilter });
  }, [customHierarchyFilter, segmentAsFilter]);

  return (
    <Card className={b()} elevation={0}>
      <div className={b('cardContainer')}>
        <div className={b('header')}>
          <Text className={b('title')}>{name}</Text>
          <Popover
            minimal
            placement="bottom-end"
            content={
              <Menu data-testid="segment-menu-actions">
                <MenuItem
                  icon={<Edit />}
                  text={formatMessage('EDIT_SEGMENT')}
                  data-testid="edit-segment-action"
                  onClick={() =>
                    setSegmentEditorDialogOptions({
                      mode: SegmentEditorDialogModes.EDIT,
                      filter: segmentAsFilter[0],
                      segment: { segmentId: id, segmentName: name }
                    })
                  }
                />
                <MenuItem
                  className={b('deleteActionButton')}
                  onClick={() =>
                    setSegmentEditorDialogOptions({ mode: SegmentEditorDialogModes.DELETE, segmentId: id })
                  }
                  icon={<TrashCan />}
                  text={formatMessage('DELETE')}
                  data-testid="delete-segment-action"
                />
              </Menu>
            }
          >
            <IconButton type="button" testId="segment-card-action-button" icon={<OverflowMenuHorizontal />} />
          </Popover>
        </div>
        <div className={b('infoContainer')}>
          <div className={b('info')}>
            <div
              data-testid="territories-count"
              className={`${b('territoryCount')} ${isLoadingTerritoriesCount && Classes.SKELETON}`}
            >
              {formatMessage('TERRITORIES_COUNT', { count: territoriesCount ?? emDash })}
            </div>
            <div className={b('footer')}>
              <div className={b('tags')}>
                <ExpandableCustomHierarchyTags customHierarchies={customHierarchies} customHierarchyFilters={filter} />
              </div>
              {orphanCount > 0 && (
                <div className={b('orphanedHierarchiesCalloutContainer')}>
                  <OrphanedHierarchiesCallout
                    segmentId={id}
                    segmentName={name}
                    filter={filter}
                    orphanCount={orphanCount}
                  />
                </div>
              )}
              {customHierarchies.length === 0 && (
                <CalloutV2
                  data-testid="empty-hierarchies-callout"
                  title={formatMessage('SEGMENT_UPDATE_REQUIRED_TITLE')}
                  intent={Intent.DANGER}
                >
                  {formatMessage('SEGMENT_UPDATE_REQUIRED_MESSAGE')}
                </CalloutV2>
              )}
              {customHierarchies.length > 0 && (
                <div className={b('applyButton')}>
                  <TextButton
                    large={false}
                    rightIcon={<ArrowRight />}
                    minimal
                    text={formatMessage('APPLY')}
                    onClick={applySegment}
                    type="button"
                    testId="select-segment-button"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const emDash = `—`;

export default SegmentCard;
